import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Logo = () => {
  const data = useStaticQuery(query)
  return <Img fluid={data.file.childImageSharp.fluid} />
}

export default Logo

export const query = graphql`
  query {
    file(sourceInstanceName: { eq: "images" }, name: { eq: "logo-w-title" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
